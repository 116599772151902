// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-competitive-intelligence-js": () => import("./../../../src/pages/competitive_intelligence.js" /* webpackChunkName: "component---src-pages-competitive-intelligence-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fee-optimization-js": () => import("./../../../src/pages/fee_optimization.js" /* webpackChunkName: "component---src-pages-fee-optimization-js" */),
  "component---src-pages-flow-forecasting-js": () => import("./../../../src/pages/flow_forecasting.js" /* webpackChunkName: "component---src-pages-flow-forecasting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-preferences-js": () => import("./../../../src/pages/investor_preferences.js" /* webpackChunkName: "component---src-pages-investor-preferences-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-templates-research-article-js": () => import("./../../../src/templates/ResearchArticle.js" /* webpackChunkName: "component---src-templates-research-article-js" */)
}

